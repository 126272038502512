import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";

//import Player from '../views/profile/Player.vue'
//import WalletRequested from "../views/Wallet/WalletRequested.vue";
//import HomeNewUser from "../views/Home/HomeNewUser.vue";
function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`);
}

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: lazyLoad("Home"),
        meta: {
            AlreadyLoged: true,
        },
    },
    {
        path: "/login",
        name: "Signin",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoad("Signin"),
        meta: {
            AlreadyLoged: true,
        },
    },

    {
        path: "/thanks",
        name: "Thanks",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Thanks.vue"),
        meta: {
            AlreadyLoged: true,
        },
    },
    {
        path: "/publicprofile/:id/1",
        name: "Publicprofile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoad("Publicprofile"),
        meta: {
            requiresAuth: false,
        },

        params: true,
    },
    {
        path: "/publicprofile/:id",
        name: "Profile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoad("Profile"),
        props: true,

        meta: {
            requiresAuth: false,
        },
    },

    {
        path: "/intsearch",
        name: "Intsearch",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoad("Intsearch"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/home-page",
        name: "HomePage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Home/HomePage.vue"),
        props: true,

        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/home-page/daily-users/:id",
        name: "HomeNewUser",
        component: () => import(/* webpackChunkName: "about" */ "../views/Home/HomeNewUser.vue"),
        props: true,
    },
    {
        path: "/home-page/blog/:id",
        name: "HomeBlogView",
        component: () => import(/* webpackChunkName: "about" */ "../views/Home/HomeBlogView.vue"),
        props: true,
    },
    {
        path: "/notifications",
        name: "Notifications",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Notifications/Notifications.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/challenges",
        name: "ChallengeNotifications",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/Notifications/ChallengeNotifications.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/thanks-verification",
        name: "ThanksVerification",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/ThanksVerification.vue"),
        meta: {
            AlreadyLoged: true,
        },
    },
    {
        path: "/chatroom",
        name: "Chatroom",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: lazyLoad("Chatroom"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue"),
        meta: {
            AlreadyLoged: true,
        },
    },
    {
        path: "/verification-mail",
        name: "VerificationMail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/VerificationMail.vue"),
        meta: {
            AlreadyLoged: true,
        },
    },

    {
        path: "/auth/password-reset-complete/:id",
        name: "resettoken",

        component: () => import(/* webpackChunkName: "about" */ "../views/Resettoken.vue"),
        meta: {
            AlreadyLoged: true,
        },

        params: true,
    },
    {
        path: "/blog",
        name: "BlogList",
        component: () => import(/* webpackChunkName: "about" */ "../views/Blog/BlogList.vue"),
        meta: {
            requiresAuth: true,
        },

        params: true,
    },
    {
        path: "/blog-create/:id",
        name: "BlogCreate",
        component: () => import(/* webpackChunkName: "about" */ "../views/Blog/BlogCreate.vue"),
        meta: {
            requiresAuth: true,
        },

        params: true,
    },

    {
        path: "/chatInner/:id",
        name: "ChatInner",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChatInner.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/wallet-login-paypal",
        name: "WalletLoginPaypal",
        component: () => import("../views/Wallet/WalletLoginPaypal.vue"),

        meta: {
            requiresAuth: true,
        },
    },

    // {
    //     path: "/wallet-old",
    //     name: "WalletOld",
    //     component: () => import("../views/Wallet/Wallet.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
        path: "/transfer-wallet",
        name: "WalletTransfer",
        component: () => import("../views/Wallet/NewWallet/TransferWallet.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/wallet",
        name: "WalletOutpost",
        component: () => import("../views/Wallet/NewWallet/OutpostWallet.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/wallet/:id",
        name: "WalletRequestPrize",
        component: () => import("../views/Wallet/WalletRequestPrize.vue"),
        children: [
            {
                path: "/1",
                name: "WalletRequested",
                component: () => import("../views/Wallet/WalletRequested.vue"),
                props: true,
            },
        ],
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/post_this_to_paypal_connect_associate/:id",
        name: "WalletPayPal",
        component: () => import("../views/Wallet/WalletPayPal.vue"),
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: "/wallet-dashboard",
        name: "WalletDashboard",
        component: () => import("../views/SA/WalletDashboard.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/1vs1-admin",
        name: "1vs1SA",
        component: () => import("../views/SA/1vs1SA.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/sendMail",
        name: "SendMail",
        component: () => import("../views/SA/SendMail.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    // {
    //     path: "/wallet-adding-payment-method",
    //     name: "WalletAddingPaymentMethod",
    //     component: () => import("../views/Wallet/WalletAddingPaymentMethod.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    //    {
    //     path: "/wallet-send",
    //     name: "WalletSend",
    //     component: () => import("../views/Wallet/WalletSend.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/wallet-transaction",
    //     name: "WalletTransaction",
    //     component: () => import("../views/Wallet/WalletTransaction.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    //   {
    //     path: "/wallet-transaction-success",
    //     name: "WalletTransactionSuccess",
    //     component: () => import("../views/Wallet/WalletTransactionSuccess.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/wallet-withdraw",
    //     name: "WalletWithdraw",
    //     component: () => import("../views/Wallet/WalletWithdraw.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/wallet-withdraw-transaction",
    //     name: "WalletWithdrawTransaction",
    //     component: () => import("../views/Wallet/WalletWithdrawTransaction.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/wallet-withdraw-transaction-success",
    //     name: "WalletWithdrawTransactionSuccess",
    //     component: () => import("../views/Wallet/WalletWithdrawTransactionSuccess.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: "/wallet-activity",
    //     name: "WalletActivity",
    //     component: () => import("../views/Wallet/WalletActivity.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },

    {
        path: "/tournament-calendar",
        name: "TournamentCalendar",
        component: () => import("../views/Tournament/TournamentCalendar.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/follow/:id",
        name: "FollowList",
        component: () => import("../views/Follow/FollowList.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/linkup-list",
        name: "LinkupList",
        component: () => import("../views/Gamer/LinkupList.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/trainer-SA",
        name: "TrainerSA",
        component: () => import("../views/SA/TrainerSA.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/trainer-list",
        name: "TrainerList",
        component: () => import("../views/Gamer/TrainerList.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/online-list",
        name: "OnlineUsers",
        component: () => import("../views/Gamer/OnlineUsers.vue"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/chess-room",
        name: "ChessTable",
        component: () => import("../views/ChessTable/ChessView.vue"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/participants-list/:id",
        name: "ParticipantsList",
        component: () => import("../views/Tournament/ParticipantsList.vue"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },

    {
        path: "/add-participants/:id",
        name: "AddParticipants",
        component: () => import("../views/Tournament/AddParticipants.vue"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tournament-inner/:id",
        name: "TournamentInner",
        component: () => import("../views/Tournament/TournamentInner"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },

    {
        path: "/register-thank-you/:id",
        name: "RegisterThankYou",
        component: () => import("../views/Tournament/RegisterThankYou"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/monri-register-thank-you/:id",
        name: "MonriRegisterThankYou",
        component: () => import("../views/Tournament/MonriRegisterThankYou"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/wallet-transfered",
        name: "WalletTransfered",
        component: () => import("../views/Wallet/NewWallet/WalletTransfered"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/monri-failure",
        name: "MonriDecline",
        component: () => import("../views/Tournament/MonriDecline"),
        props: true,
        meta: {
            noRequiresAuth: true,
        },
    },
    {
        path: "/tournament-inner/:id",
        name: "TournamentInnerOrg",
        component: () => import("../views/Tournament/TournamentInnerOrg"),
        props: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-personal",
        name: "EditPersonal",
        component: () => import("../views/Edit/EditPersonal.vue"),
        params: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-name",
        name: "EditName",
        component: () => import("../views/Edit/EditName.vue"),
        params: true,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-birthday",
        name: "EditBirthday",
        component: () => import("../views/Edit/EditBirthday.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-federation",
        name: "EditFederation",
        component: () => import("../views/Edit/EditFederation.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-gender",
        name: "EditGender",
        component: () => import("../views/Edit/EditGender.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-fide",
        name: "EditFide",
        component: () => import("../views/Edit/EditFide.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data",
        name: "EditChess",
        component: () => import("../views/Edit/EditChess.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-biography",
        name: "EditBiography",
        component: () => import("../views/Edit/EditBiography.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data/organizer",
        name: "EditOrganizer",
        component: () => import("../views/Edit/EditChessData/EditOrganizer.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data/chess-boxer",
        name: "EditChessBoxer",
        component: () => import("../views/Edit/EditChessData/EditChessBoxer.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data/trainer",
        name: "EditTrainer",
        component: () => import("../views/Edit/EditChessData/EditTrainer.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data/player",
        name: "EditPlayer",
        component: () => import("../views/Edit/EditChessData/EditPlayer.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-chess-data/arbiter",
        name: "EditArbiter",
        component: () => import("../views/Edit/EditChessData/EditArbiter.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create-event",
        name: "CreateEvent",
        component: () => import("../views/Event/CreateEvent.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create-event-1",
        name: "CreateEventOld",
        component: () => import("../views/Event/CreateEventOld.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create-event-success",
        name: "CreateEventSuccess",
        component: () => import("../views/Event/CreateEventSuccess.vue"),
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    routes,
    mode: "history",
    /*  base: process.env.BASE_URL,

      scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },  */
});

router.beforeEach((to, from, next) => {
    if (to.fullPath.substr(0, 2) === "/#") {
        const path = to.fullPath.substr(2);
        next(path);
        return;
    }

    window.scrollTo(0, 0);

    if (to.meta.requiresAuth) {
        if (!localStorage.getItem("token")) {
            const urlParams = new URLSearchParams(window.location.search);
            console.log("roko", urlParams);

            const token = urlParams.get("token");

            const id = urlParams.get("id");
            // console.log('roko',token)
            // console.log(id)
            if (token && id) {
                // console.log("Token received:", token);
                // console.log("Id received:", id);
                localStorage.setItem("token", token);
                localStorage.setItem("id", id);
                next();
            } else {
                console.log("krstar", new URLSearchParams(window.location.search));
                localStorage.removeItem("id");
                next({ name: "Signin", query: { redirect: to.fullPath } });
            }
        } else {
            /*  else if( to.meta.requiresAuth && to.meta.paypal){
         
            next({ name: "CreateEvent" });
            
        }*/
            next();
        }
    } else if (to.meta.AlreadyLoged) {
        if (localStorage.getItem("token")) {
            next({ name: "HomePage" });
            //next({ name: "Profile", params: { id: localStorage.getItem("id") } });
        } else {
            next();
        }
    } else if (to.meta.noRequiresAuth) {
        next();
    } else {
        next();
    }
});

export default router;
