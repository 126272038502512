export const allUsers = (state, storeUsers) => {
    state.storeUsers = storeUsers;
    // console.log("mrkela",  state.storeUsers);
};
export const SET_INTSEARCH_PAGE_NUMBER = (state, number) => {
    state.pageNumberPagination = number;
    // console.log("pagination",  state.pageNumberPagination);
};
export const boostedTour = (state, boostedTour) => {
    state.boostedTour = boostedTour;
    // console.log("boost", state.boostedTour);
};
export const myChallengesData = (state, myChallengesData) => {
    state.myChallenges = myChallengesData;
    // console.log("boost", state.boostedTour);
};

export const SET_TITULE = (state, titule) => {
    // console.log("titule", titule);
    state.titule = titule;
};
export const NEW_PICTURE = (state, picture) => {
    // console.log("titule", titule);
    state.newProfilePic = picture;
};

export const SET_COUNTRIES = (state, listOfCountries) => {
    //  console.log("countries", listOfCountries);
    state.listOfCountries = listOfCountries;
};
export const MESSAGE_LIST = (state, messages_contact_list) => {
    // console.log("mrkela", messages_contact_list);
    state.messages_contact_list = messages_contact_list;
};
export const setFlags = (state, storeFlags) => {
    // console.log("mrkela", storeFlags);
    state.storeFlags = storeFlags;
};
export const MORE_SIDEBAR = (state, moreSidebar) => {
    // console.log("mrkela", storeFlags);
    state.moreSidebar = moreSidebar;
};

export const SET_SEARCH = (state, tournamentSearch) => {
    state.tournamentSearch = tournamentSearch;
    // console.log("search",state.tournamentSearch)
};
export const SET_MY_NOTIFICATION = (state, notif_arr2) => {
    state.notify_array = notif_arr2;
    //  console.log("not array",state.notify_array)
};

export const SET_INT_CLUB = (state, club) => {
    state.interestedClub = club;
    // console.log("not array",state.notify_array)
};

export const SET_INT_PLAYER = (state, player) => {
    state.interestedPlayer = player;
};

export const SET_MY_NOTIFICATION_CHALLENGE = (state, challenge_arr) => {
    state.notify_array_challange = challenge_arr;
    //  console.log("not array",state.notify_array)
};
export const SET_POPUP_SIGN_IN = state => {
    state.popupOnSignIn = true;
    //  console.log("not array",state.notify_array)
};
export const SET_CHECKBOX_FIDE = (state, checkbox) => {
    state.enterDataFIDE = checkbox;
    //console.log("checbox checker",state.enterDataFIDE)
};
export const SET_MY_NOTIFICATION_LOADED = (state, is_loaded) => {
    state.notification_loaded = is_loaded;
    //  console.log("loaded",state.notification_loaded)
};
// export const SET_MY_NOTIFICATION_NEW = (state, payload) => {
//     this.notify_array = [payload, ...this.notify_array];
//     state.notify_array.push(...new_notification);
//      console.log("not array",state.notify_array)
// };

export const SET_TIMEZONE = (state, timezone) => {
    state.current_user.time_zone = timezone;
    //  console.log("search",state.current_user.trainer_price_lesson)
};
export const SET_PRICE = (state, trainer_price) => {
    state.current_user.trainer_price_lesson = trainer_price;
    //  console.log("search",state.current_user.trainer_price_lesson)
};
export const SET_PRICE_LINKUP = (state, trainer_price) => {
    state.current_user.trainer_price_linkup = trainer_price;
};
export const SET_COUNTRY_SEARCH = (state, countrySearch) => {
    state.countrySearch = countrySearch;
    // console.log("search", state.countrySearch);
};
export const SET_CONTINENT = (state, continentsChecked) => {
    state.continentsChecked = continentsChecked;
    //  console.log("search", state.continentsChecked);
};
export const SET_COUNTRY = (state, countriesChecked) => {
    state.countriesChecked = countriesChecked;
    //  console.log("search", state.countriesChecked);
};
export const SET_TC = (state, TCChecked) => {
    state.TCChecked = TCChecked;
    //console.log("search", state.TCChecked);
};

export const SET_YEAR_SEARCH = (state, DateYearChecked) => {
    state.DateYearChecked = DateYearChecked;
};
export const SET_MONTH_SEARCH = (state, DateMonthChecked) => {
    state.DateMonthChecked = DateMonthChecked;
};
export const SET_AGE_TO = (state, ageto) => {
    state.ageto = ageto;
    // console.log("search1", state.ageto);
};
export const SET_AGE_FROM = (state, agefrom) => {
    state.agefrom = agefrom;
    // console.log("search2", state.ageto);
};
export const SET_RATING_FROM = (state, ratingfrom) => {
    state.ratingfrom = ratingfrom;
    //console.log("search3", state.ratingfrom);
};
export const SET_RATING_TO = (state, ratingto) => {
    state.ratingto = ratingto;
    // console.log("search4", state.ratingto);
};

export const SET_COUNTRY_INTELEGENT = (state, countriesChecked2) => {
    state.countriesChecked2 = countriesChecked2;
    // console.log("search90", state.countriesChecked2);
};

export const SET_TITLE = (state, titleselected2) => {
    state.titleselected2 = titleselected2;
    //console.log("search4", state.titleselected2);
};
export const SET_TITLE3 = (state, titleselected3) => {
    state.titleselected3 = titleselected3;
    //console.log("search4", state.titleselected2);
};
export const SET_ROLE = (state, role) => {
    state.role = role;
    // console.log("search4", state.role);
};
export const SET_TOWN = (state, searchtown) => {
    state.searchtown = searchtown;
    // console.log("search8", state.searchtown);
};
export const SET_INTERESTED_CLUB = (state, interestedclub) => {
    state.interestedclub = interestedclub;
    // console.log("search4", state.interestedclub);
};
export const SET_INTERESTED_TOUR = (state, interestedtour) => {
    state.interestedtour = interestedtour;
    //console.log("search4", state.interestedtour);
};
export const SET_INTERESTED_EVENT = (state, interestedevent) => {
    state.interestedevent = interestedevent;
    //  console.log("search4", state.interestedevent);
};
export const SET_INTERESTED_TEXT = (state, input_text) => {
    state.input_text = input_text;
    // console.log("search4", state.input_text);
};
export const SET_SEX = (state, sexon) => {
    state.sexon = sexon;
    // console.log("search4", state.sexon);
};
export const SET_VERIFIED = (state, verified) => {
    state.verified = verified;
    // console.log("search4", state.sexon);
};
export const SET_SIDEBAR = (state, clickside1) => {
    state.clickside1 = clickside1;
    //  console.log("search4", state.clickside1);
};
export const SET_COUNTRY_SEARCH_INT = (state, countrySearchINT) => {
    state.countrySearchINT = countrySearchINT;
    //  console.log("search10", state.countrySearchINT);
};
export const SET_USER_CURRENT = (state, current_user) => {
    state.current_user = current_user;
    //console.log("juzer", state.current_user);
};

export const SET_USER_CURRENT_NAME = (state, name_first) => {
    state.current_user.name_first = name_first;
};
export const SET_USER_CURRENT_NAME_LAST = (state, name_last) => {
    state.current_user.name_last = name_last;
};
export const SET_USER_CURRENT_GENDER = (state, sexon) => {
    state.current_user.sex = sexon;
};
export const SET_USER_CURRENT_BIRTHDAY = (state, dob) => {
    state.current_user.dob = dob;
};
export const SET_USER_CURRENT_FEDERATION = (state, key) => {
    // console.log("25", localStorage.getItem("country"));
    state.current_user.federation = key;
    state.current_user.federation_details.name = localStorage.getItem("country");
};
export const SET_MESSAGE_INFO = (state, message_info) => {
    state.message_info = message_info;
    //  console.log("check message", state.message_info);
    state.reload = true;
};

export const SET_WALLET = (state, walletSum) => {
    //  console.log("checkerica", walletSum);
    state.walletSum = walletSum;
    if (walletSum == null) {
        state.walletSum = 0;
    }
    //console.log("checkerica", state.walletSum);
};
export const SET_TOURNAMENTS = (state, tournaments) => {
    state.tournaments = tournaments;
    //console.log("check message", state.tournaments);
};
export const SET_USER_CURRENT_FIDE = (state, fide) => {
    state.current_user.fide_id = fide;
};
export const SET_USER_CURRENT_USCF = (state, uscf) => {
    state.current_user.uscf_id = uscf;
};

export const SET_NEW_MESSAGES = (state, message_info) => {
    state.message_info = message_info; // console.log("proverijana", state.message_info);
};
export const SET_MY_BLOGS = (state, my_blogs) => {
    state.my_blogs = my_blogs;
    //   console.log("blogici", state.my_blogs);
};
export const SET_BLOG_ACTIVE = (state, clickActive) => {
    state.blogActive = clickActive;
    //   console.log("blogici", state.my_blogs);
};
export const SET_MY_FEEDS = (state, feeds) => {
    //  if (feeds == 0) {
    //      state.feeds = [];
    //  } else {
    state.feeds = feeds;
    //  }
    //console.log("fidovi", state.feeds);
};
export const SET_NUMBER_MY_FEEDS = (state, feeds) => {
    state.feedStartNumber = feeds;
    //  console.log("StartNo", state.feedStartNumber);
};

export const SET_DATE_FEEDS = (state, dateFeeds) => {
    state.dateFeeds = dateFeeds;
    // console.log("date", state.dateFeeds);
};
export const SET_FRESH_FEEDS = (state, feeds) => {
    state.feeds = feeds;
    //  state.feeds.unshift(...freshFeeds);
    //console.log('check1',state.feedStartNumber)
    //console.log('check2',freshFeeds.length)
    // console.log('check3',state.feeds)

    //let difference = freshFeeds.length - state.feedStartNumber + 1;

    //for (let i = 0; i < difference; i++) {
    //    state.feeds.unshift(freshFeeds[i]);
    // }
};
export const SET_MORE_FEEDS = (state, moreFeeds) => {
    state.feeds.push(...moreFeeds);
    //console.log("more", state.feeds);
};
export const SET_HOME_SCROLLTOP = (state, scrollTopDOM) => {
    state.scrollTopDOM = scrollTopDOM;
    //   console.log("scrollposition", state.scrollTopDOM);
};
export const SET_CALENDAR_SCROLLTOP = (state, scrollTopCalendar) => {
    state.scrollTopCalendar = scrollTopCalendar;
    //   console.log("scrollposition", state.scrollTopDOM);
};

export const SET_HOME_NEW_USERS = (state, feed) => {
    state.feed = feed;
    // console.log("feed", state.feed);
};
export const SET_BLOG_VIEW = (state, blog_feed) => {
    state.blog_feed = blog_feed;
    // console.log("feed", state.blog_feed);
};
export const SET_BLOG_VIEW_PROFILE = (state, blog_feed_profile) => {
    state.blog_feed_profile = blog_feed_profile;
    // console.log("feed_profile", state.blog_feed_profile);
};
export const LOADED_CALENDAR = (state, calendar) => {
    state.calendar = calendar;
    //console.log("feed", state.calendar);
};
export const MESSAGE_ALERT = (state, unreadMessage) => {
    state.unreadMessage = unreadMessage;
    //console.log("unreadMessage", state.unreadMessage);
};

export const NOTIFICATION_PAYLOAD = (state, payload) => {
    state.notificationPayload = payload;
    //  console.log("notification_payload", typeof(state.notificationPayload));
};
export const SET_ROLE_SCREEN = (state, rola) => {
    state.rola = rola;
    //console.log("naktivna rola", state.rola);
};
export const SET_ROLE_SCREEN_PUBLIC = (state, rolaPublic) => {
    state.rolaPublic = rolaPublic;
    //console.log("naktivna rola", state.rola);
};
export const ONLINE_USERS = (state, payload) => {
    state.onlinePayload = payload;
    //   console.log("online_users", state.onlinePayload);
};
export const UPLOADED_PIC = (state, response) => {
    state.uploaded_image = response;
    //  console.log("uploaded", state.uploaded_image);
};
export const SET_WALLET_POSITION = (state, e) => {
    state.walletPosition = e;
};
export const MY_CONNECTION_LIST = (state, payload) => {
    let followers = [];
    let following = [];
    let listFollowingUuid = [];
    for (let i = 0; i < payload.length; i++) {
        if (
            payload[i].i_am_initiator == true &&
            payload[i].connection_details?.user_uuid &&
            payload[i].connection_details.name_first &&
            payload[i].conn_status !== "A"
        ) {
            following = [...following, payload[i]];
            listFollowingUuid = [...listFollowingUuid, payload[i].connection_details.user_uuid];
        } else if (
            payload[i].connection_details?.user_uuid &&
            payload[i].connection_details.name_first &&
            payload[i].conn_status !== "A"
        ) {
            followers = [...followers, payload[i]];
        }
    }
    state.listFollowingUuid = listFollowingUuid;
    state.myConnectionsFollowers = followers;
    state.myConnectionsFollowing = following;
    state.connectionDownloaded = true;
    // console.log('konekcija',listFollowingUuid)
};
export const ADD_FOLLOWING = (state, idt) => {
    let listFollowingUuid = state.listFollowingUuid;
    listFollowingUuid = [...listFollowingUuid, idt];
    state.listFollowingUuid = listFollowingUuid;
};
export const DELETE_FOLLOWING = (state, idt) => {
    let listFollowingUuid = state.listFollowingUuid;
    for (let i = 0; i < listFollowingUuid.length; i++) {
        if (listFollowingUuid[i] == idt) {
            listFollowingUuid.splice(i, 1);
        }
    }

    state.listFollowingUuid = listFollowingUuid;
    // console.log("delete fol", state.listFollowingUuid);
};

export const GROUPS_FILTER = (state, numberGroupNow) => {
    state.numberGroupNow = numberGroupNow;
};
export const SET_ROUTE_PROFILE = (state, datum) => {
    state.datumCalendar = datum.substr(datum.indexOf("-")).slice(2, 55);
    state.calendarActive = true;
    // console.log("datum", state.datumCalendar);
    // console.log("datum", state.calendarActive);
};
export const CANCEL_ROUTE_PROFILE = state => {
    state.datumCalendar = "";
    state.calendarActive = false;
    //  console.log("datum", state.datumCalendar);
};
export const SET_FOLLOW_TABLE = (state, startPoint) => {
    state.followTablePosition = startPoint;
    //   console.log("follow position table", state.followTablePosition);
};

export const ERASE_STATES = (state, number) => {
    if (number == 1) {
        (state.agefrom = ""),
            (state.ageto = ""),
            (state.ratingfrom = ""),
            (state.ratingto = ""),
            (state.searchtown = ""),
            (state.sexon = ""),
            (state.interestedclub = ""),
            (state.interestedtour = ""),
            (state.interestedevent = ""),
            (state.countriesChecked2 = []),
            (state.input_text = ""),
            (state.role = []),
            (state.clickside1 = false),
            (state.countrySearchINT = ""),
            (state.activeOrg = ""),
            (state.verified = ""),
            (state.titleselected2 = []),
            (state.titleselected3 = []),
            (state.myConnectionsFollowers = []),
            (state.myConnectionsFollowing = []),
            (state.connectionDownloaded = false);
    }
};
